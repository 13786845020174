import { useParams } from "react-router-dom"

const ProjectDetails = () => {
  const {projectName} = useParams()

  return (
    <div>ProjectDetails for: {projectName}</div>
  )
}

export default ProjectDetails