import "./App.css";

import { createBrowserRouter, createRoutesFromElements, RouterProvider, Route } from "react-router-dom";

import Home from "./pages/Home";
import Projects from "./pages/Projects";
import ProjectDetails from "./pages/ProjectDetails";

import { getAllProjectsData } from "./data/all_projects/imports";

// import { getTestDataArr, getTestDataDynamic, getTestDataObj } from "./data/imports";
// import TestObj from "./pages/TestObj";
// import TestArr from "./pages/TestArr";
// import DynamicPath from "./pages/DynamicPath";

// todo
// create a route with dynamic path, and has a loader function which uses the param object to return different data from the imports


function App() {
 

  const router = createBrowserRouter(
    
    createRoutesFromElements(
      <>
      <Route path="/" element={<Home/>} />
      <Route path="/projects" element={<Projects/>} loader={getAllProjectsData} />
      <Route path="/projectDetails/:projectName" element={<ProjectDetails/>} />



      {/* <Route path="/testObj" element={<TestObj/>} loader={getTestDataObj} />
      <Route path="/testArr" element={<TestArr/>} loader={getTestDataArr} />
      <Route path="/testDynamicPath/:id" element={<DynamicPath/>} loader={getTestDataDynamic} /> */}
      </>

    )
    

  )

  return (
    <RouterProvider router={router}/>
  );
}

export default App;
